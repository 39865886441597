import { Folder, FolderData } from 'src/views/CRUD/types';
import { UPDATE_TYPES } from '../interfaces';

export enum FoldersActions {
  SET_FOLDERS = 'SET_FOLDERS',
  CREATE_FOLDER = 'CREATE_FOLTER',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  UPDATE_DASHBOARD_IN_FOLDER = 'UPDATE_DASHBOARD_IN_FOLDER',
}

export const createFolder = (data: Folder) => ({
  type: FoldersActions.CREATE_FOLDER,
  data,
});

export const deleteFolder = (id: number) => ({
  type: FoldersActions.DELETE_FOLDER,
  id,
});

export const updateFolder = (data: FolderData, updateTye?: UPDATE_TYPES) => ({
  type: FoldersActions.UPDATE_FOLDER,
  data: { updatedFolder: data, updateTye },
});

export const updateDashboardInFolder = (data: FolderData) => ({
  type: FoldersActions.UPDATE_DASHBOARD_IN_FOLDER,
  data: { updatedDashboard: data },
});

export const setFolders = (folders: Array<Folder>, dashboards = []) => ({
  type: FoldersActions.SET_FOLDERS,
  data: { folders, dashboards },
});
