import React from 'react';
import { useTheme } from '@superset-ui/core';
import { Tooltip as AntdTooltip } from 'antd';
import {
  TooltipProps,
  TooltipPlacement as AntdTooltipPlacement,
} from 'antd/lib/tooltip';

export type TooltipPlacement = AntdTooltipPlacement;

export const Tooltip = (props: TooltipProps) => {
  const theme = useTheme();
  return (
    <AntdTooltip
      overlayStyle={{
        fontSize: theme.typography.sizes.m,
        lineHeight: '20px',
        color: theme.colors.grayscale.light5,
        ...props.overlayStyle,
      }}
      overlayInnerStyle={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: 40,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        fontFamily: 'MTSCompactRegular, sans-serif',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        ...props.overlayInnerStyle,
      }}
      color={`${theme.colors.grayscale.dark75}`}
      {...props}
    />
  );
};
