import { ReleaseActions } from '../actions/releaseActions';

type ActionProps = {
  type: ReleaseActions;
  flag: boolean;
};

const defaultState = {
  /** Надо ли смотреть новый релиз в документации */
  isNeedCheckDocumentationRelease: false,
};

export default function releaseReducers(
  state = defaultState,
  action: ActionProps,
) {
  switch (action.type) {
    case ReleaseActions.SET_RELEASE_UPDATE: {
      return {
        ...state,
        isNeedCheckDocumentationRelease: action.flag,
      };
    }
    default:
      return state;
  }
}
