export const PASSWORDS_NEEDED_EN = 'Must provide a password for the database';
export const PASSWORDS_NEEDED_RU = 'Необходимо указать пароль для базы данных';

export const SSH_PASSWORD_NEEDED_EN =
  'Must provide a password for the ssh tunnel';
export const SSH_PASSWORD_NEEDED_RU =
  'Необходимо указать пароль для SSH-туннеля';

export const SSH_PRIVATE_KEY_NEEDED_EN =
  'Must provide a private key for the ssh tunnel';

export const SSH_PRIVATE_KEY_NEEDED_RU =
  'Необходимо указать приватный ключ для SSH-туннеля';

export const SSH_PRIVATE_KEY_PASSWORD_NEEDED_EN =
  'Must provide a private key password for the ssh tunnel';

export const SSH_PRIVATE_KEY_PASSWORD_NEEDED_RU =
  'Необходимо указать пароль для приватного ключа SSH-туннеля';

export const ALREADY_EXIST_EN =
  'already exists and `overwrite=true` was not passed';

export const ALREADY_EXIST_RU =
  'уже существует, и параметр overwrite=true не был передан';
